html,
body {
  font-family: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 20px;
  background-color: #ffffff;
  color: #868e96;
  transition: background-color .2s ease-in-out;
}
body.dark {
  background-color: #212121;
}

body.dark  hr {
  border-color: #4d5154;
}

#darkMode {
  position: absolute;
  right: 80px;
  width: 45px;
  height: 45px;
  font-size: 1.3rem;
  border: none;
  border-radius: 50%;
  background-color: #EFEFEF;
  color: #4f5459;
  transition: background-color .3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
#darkMode:focus {
  outline: none;
}
#darkMode:hover {
  background-color: #dddddd;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Saira Extra Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 700;
  text-transform: uppercase;
  color: #343a40;
}
body.dark h1,
body.dark h2,
body.dark h3,
body.dark h4,
body.dark h5,
body.dark h6  {
  color: #dddddd;
}

a {
  color: #868e96;
  font-weight: bold;
}
a:hover {
  text-decoration: underline;
}
a:hover, a:focus, a:active, a:visited {
  color: #868e96;
}
a:not([href]):hover {
  cursor: pointer;
}
.text-black {
  color: #343a40;
}
body.dark .text-black {
  color: #efefef;
}

.resume-section {
  max-width: 600px;
  min-height: 100vh;
  margin: auto;
  padding: 3rem 1rem !important;
}
#home.resume-section {
  max-width: fit-content;
}
@media (max-width: 992px) {
  .resume-section {
    max-width: 100%;
  }
}

#home .intro-container{
  margin: auto;
  display: flex;
  justify-content: center;
}
#home .intro-content{
  position: relative;
  left: -10%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
#home .intro-content p {
  background: #EDEDED;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 300;
  display: inline;
}
#home .intro-content p a {
  color: inherit;
  font-weight: 500;
}
#home .intro-content p a:hover {
  text-decoration: underline;
}
#home .profile-img {
  width: 328px;
}
#home .header {
  font-size: 6rem;
  font-weight: 700;
  white-space: nowrap;
}
#home .fname {
  color: #01ffa8;
}
#home .lname {
  color: #343a40;
}
body.dark #home .lname {
  color: #ededed;
}
#home .subheader {
  color: #868e96;
  text-align: right;
}
#home .social-icons {
  margin: 1rem 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#home .social-icons .icon {
  font-size: 1.5rem;
  margin: 0 1rem;
  opacity: .6;
  color: #868e96;
  transition-duration: 400ms;
}
body.dark #home .social-icons .icon {
  color: #d9d9d9;
}
#home .social-icons .icon::after {
  display: block;
  content: attr(title);
  overflow: hidden;
  visibility: hidden;
}
#home .social-icons .icon:hover {
  opacity: 1;
  transform: scale(1.2);
}
@media (max-width: 992px) {
  #home .intro-container{
    flex-direction: column;
  }
  #home .profile-img {
    width: 246px;
  }
  #home .intro-content {
    max-width: 100%;
    left: 0;
    align-items: flex-end;
  }
  #home .header {
    white-space: normal;
    line-height: 1;
    font-size: 4rem;
  }
}

#skills .dev-icons {
  font-size: 2.5rem;
}
#skills .dev-icons .list-inline-item {
  margin: 0 0.6rem;
}
#skills .dev-icons .icon-java:hover { color: #EB2D2F; }
#skills .dev-icons .icon-spring:hover { color: #68BD45; }
#skills .dev-icons .icon-javascript:hover { color: #FFC732; }
#skills .dev-icons .icon-reactjs:hover { color: #5ed4f3; }
#skills .dev-icons .icon-oracle:hover { color: #F80000; }
#skills .dev-icons .icon-mysql:hover { color: #00618B; }
#skills .dev-icons .icon-mongodb:hover { color: #257200; }

@media (min-width: 992px) {
  #sideNav {
    top: 10px;
  }
  body.dark #sideNav .navbar-nav {
    background-color: #343a40 !important;
  }
  body.dark .navbar-nav .nav-item .nav-link {
    color: #bababa !important;
  }
}
#sideNav .navbar-nav {
  background-color: #F7F7F7;
}
#sideNav .navbar-nav .nav-item .nav-link {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .05rem;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 .5rem;
  margin: .5rem;
}
#sideNav .navbar-nav .nav-item .nav-link:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}
#sideNav .navbar-nav .nav-item .nav-link.active {
  background-color: #00FFA7;
  color: #343a40 !important;
}
#sideNav .navbar-nav .nav-item .nav-link {
  color: inherit;
}
#sideNav .navbar-toggler {
  border: none;
  outline: none;
}
#sideNav .navbar-brand {
  display: none;
}
#sideNav .navbar-brand img {
  width: 28px;
  margin-right: 0.5rem;
}
#sideNav .navbar-collapse {
  width: 100%;
  justify-content: center;
}

@media (max-width: 992px) {
  #sideNav {
    background-color: white;
  }
  body.dark #sideNav {
    background-color: white;
  }
  #sideNav.nav-item:focus, #sideNav.nav-:focus {
    color: #00FFA7 !important;
  }
  #responsive-navbar-nav {
    width: fit-content !important;
    transition-duration: 400ms;
    position: absolute;
    top: 40px;
    right: 50px;
    background-color: #f7f7f7;
    padding: 0 2rem;
  }
  #sideNav .navbar-brand {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #343a40 !important;
  }
}

#work .work-infos, #work .collapse-text {
  transition-duration: 1000ms;
}
#work .oss .card {
  border: none;
  border-radius: 1.5px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 8px 26px 0px !important;
  transition-duration: .3s;
}
 #work .oss .card:hover,.oss .card:focus {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px !important;
}
@media (max-width: 992px) {
  #work .work-wrapper {
    width: 100%;
  }
  #work .work-infos {
    margin-bottom: 4rem!important;
  }
}

.footer {
  text-align: center;
}
.footer .peace-icn {
  font-size: 2rem;
}